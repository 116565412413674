
import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import OuterHeader from '../components/OuterHeader'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Slider from "react-slick";
import Select2 from '../pages/Select2';
import ScrollToTop from '../components/ScrollToTop';
import Swal from 'sweetalert2'
import { todayAffirmation, ContentTags, ContentList, ContentDefaultlist, AddBook } from '../api/apiHandler.js'
import '../css2/select2.css'
import '../css2/select2.css'
import Results from './Results.js';
import Book from '../components/Book.js';
import Video from '../components/Video.js';
import Audio from '../components/Audio.js';
import Artical from '../components/Artical.js';
import DefaultContent from '../components/DefaultContent.js';
import { event } from 'jquery';
import Header from '../components/Header.js';
import axios, { all } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';
import Loader from '../components/Loader';



const Resources = () => {
    const isLoading = useSelector((state) => state.loader);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [chatGptResponse, setChatGptResponse] = useState([]);
    const contentData = useSelector((state) => state.contentdetails);
    const [scroll, setScroll] = useState(true)
    const [state, setState] = useState(false);
    const [affirmationdata, setAffirmationdata] = useState([]);
    const [content, setContent] = useState([]);
    const [title, setTitle] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [selectedlength, setSelectedlength] = useState();
    const [defaultcontent, setDefaultContent] = useState();
    const [searchQuery, setSearchQuery] = useState(localStorage.getItem("user_search") != null ? localStorage.getItem("user_search") : "");
    const [searchlength, setSearchlength] = useState(localStorage.getItem("content_length"));
    const [manageDefaultContent, setManageDefaultContent] = useState(false);
    const [survayData, setSurvayData] = useState(null);
    const [bookResponse, setBookResponse] = useState([]);


    // const [contentQuestion, setContentQuestion] = useState(localStorage.getItem("content_question") ? "true" : "false")

    // useEffect(() => {
    //     if (contentQuestion != "true") {
    //         localStorage.removeItem("user_search")
    //         localStorage.removeItem("user_category")
    //         localStorage.removeItem("content_length")
    //     }
    // }, [])

    // if (localStorage.getItem("user_category") == "Type of Content" || localStorage.getItem("content_length") == "Length of Content") {
    //     localStorage.removeItem("user_search")
    //     setSearchQuery("")
    //     localStorage.removeItem("user_category")
    //     setSelectedValue("")
    //     localStorage.removeItem("content_length")
    //     setSelectedlength("")
    // }


    const tagarray = [
        { "id": 1, "tag": "Self Help" },
        { "id": 2, "tag": "Motivation" },
        { "id": 3, "tag": "Anxiety" },
        { "id": 4, "tag": "Growth and Development" },
        { "id": 5, "tag": "ADHD" },
    ]
    const [tags, setTags] = useState(tagarray);


    const handleSearchChange = (e) => {

        localStorage.setItem("user_search", e.target.value)
        setSearchQuery(e.target.value);
    };

    const handleInputValue = (e) => {
        localStorage.setItem("user_search", e.target.innerText)
        setSearchQuery(e.target.innerText);
    };

    const handleInputlength = (e) => {
        localStorage.setItem("content_length", e.target.innerText)
        setSearchlength(e.target.innerText);
    };


    // const filteredItems = items && items.filter(item =>
    //     item.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    const filteredItems = tags && tags.filter(item =>
        item.tag.toLowerCase().includes(searchQuery?.toLowerCase())
    );




    const SearchData = () => {
        localStorage.removeItem("survayresponse")
        // setContentQuestion("true")
        setSelectedValue(localStorage.getItem("user_category"))
        setSelectedlength(localStorage.getItem("content_length"))
        switch (localStorage.getItem("content_length")) {
            case 'Short (<5 minutes)':
                localStorage.setItem("start_length", 0)
                localStorage.setItem("end_length", 5)
                break;
            case 'Medium (5-10 minutes)':
                localStorage.setItem("start_length", 5)
                localStorage.setItem("end_length", 10)
                break;
            case 'Long (10-30 minutes)':
                localStorage.setItem("start_length", 10)
                localStorage.setItem("end_length", 30)
                break;
            case 'Super Long (30+ minutes)':
                localStorage.setItem("start_length", 30)
                localStorage.setItem("end_length", 10000)
                break;
            default:
                localStorage.setItem("start_length", "")
                localStorage.setItem("end_length", "")
                break;
        }

        dispatch(setLoading(true))
        ContentList({ page: 1 }).then((response) => {
            if (response.status == 200) {
                dispatch(setLoading(false))
                if (localStorage.getItem("user_category") == "Book") {
                    
                    if(response.data.books){
                        if(response.data.books.length > 0){
                            setChatGptResponse(response.data.books);
                        }
                    }
                    else{
                        setContent(response.data?.results)
                    }    
                }
                setContent(response.data?.results)
                
            }
            else if (response.status == 204) {
                
                    dispatch(setLoading(false))
                    // setContentQuestion("false")
                    setManageDefaultContent(true)
                    // localStorage.removeItem("user_search")
                    // setSearchQuery("")
                    // localStorage.removeItem("user_category")
                    // setSelectedValue("")
                    localStorage.removeItem("content_length")
                    setSelectedlength("")
                    Swal.fire({
                        position: "top-right",
                        icon: "info",
                        title: "Didn't found any content for your searched query",
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    setContent([])
                
            }

        }).catch((error) => {
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response ? error.response.data.errors : "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        })

        // localStorage.removeItem('user_search')
        // localStorage.removeItem('user_category')
        // localStorage.removeItem('start_length')
        // localStorage.removeItem('end_length')
    }


    // console.log(typeof localStorage.getItem("content_question"), "contentquestion");
    useEffect(() => {
        if (content.length == 0) {
            var surveydata = JSON.parse(localStorage.getItem("survayresponse"))
            console.log('surveydata: ', surveydata);
            surveydata != null ? setContent(surveydata) : setContent([])
            // localStorage.removeItem("content_question")
        }

    }, [])


    useEffect(() => {
        todayAffirmation().then((response) => {
            if (response.status == 200) {
                setAffirmationdata(response.data)
            } else if (response.status == 204) {
                Swal.fire({
                    position: "top-right",
                    icon: "info",
                    title: " No affirmation found for today",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }).catch((error) => {
            console.log(error);
            // Swal.fire({
            //     position: "top-right",
            //     icon: "error",
            //     title: error.response.data.errors,
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 2000
            // });
        })

        if (localStorage.getItem("user_category") != "" && localStorage.getItem("user_search") != "" && localStorage.getItem('user_category') && localStorage.getItem('user_search')) {
            SearchData()

        } else {

        }
        // setSelectedValue(localStorage.getItem("user_category"))
    }, [])


    const handlesavecontent = (event) => {
        const selectedValue = event.target.value;
        if (event.target.value != undefined && event.target.value != "") {
            localStorage.setItem("user_category", event.target.value)
            localStorage.removeItem("questions_for")
        }
        // setSelectedValue(event.target.value)
    };

    const handlesavecontentlength = (event) => {
        const selectedlength = event.target.value;
        localStorage.setItem("content_length", event.target.value)

    }

    const ResetData = () => {
        localStorage.removeItem("user_search")
        setSearchQuery("")
        localStorage.removeItem("user_category")
        setSelectedValue("")
        localStorage.removeItem("content_length")
        setSelectedlength("")
    }



    const chatGptApiCall = async () => {
        let prompt =
            `Please recommend me 5 books focused on the given keyword, the book should have great reviews, should be well received and helpful. Provide book title, very brief description of the book and book author for ${localStorage.getItem('user_search')} in the following format: \n\n"
            "Book Title: [Title]\n"
            "Book Author: [Author Name]\n"
            "ISBN: [ISBN Number]\n\n"
            "Amazon buy link with amazon affiliate associate tag  helprrai-20 \n\n "
            "Please provide multiple recommendations"
            "Please provide JSON format"`
        console.log("prompt", prompt);

        let requestData = {
            "model": "gpt-3.5-turbo-instruct",
            "max_tokens": 512,
            "temperature": 0,
            "prompt": prompt
        };

        axios.post(process.env.REACT_APP_CHATGPT_API_URL, requestData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_CHATGPT_API_KEY}`
            },
        }).then((res) => {

            const parsedData = res.data.choices[0].text;
            const jsonData = JSON.parse(parsedData);

            const books = jsonData.map(book => ({
                title: book["Book Title"],
                buyLink: book["Amazon buy link with amazon affiliate associate tag"] || book["Amazon buy link"]
            }));

            console.log('books: ', books);
            if (books.length > 0) {
                setChatGptResponse(books);
            }

        })
            .catch((error) => {
                dispatch(setLoading(false))
                Swal.fire({
                    position: "top-right",
                    icon: "error",
                    title: "Something went wrong",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                // console.error("chatgpr---error---------", error);
            });
    }

    const googleBookApi = (book_data) => {
        const encodedSearchTerm = encodeURIComponent(book_data.title);
        const apiUrl = `${process.env.REACT_APP_GOOGLE_BOOK_API_URL}/volumes?q=${encodedSearchTerm}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
        // console.log("googel book url", apiUrl);
        axios.get(apiUrl).then((res) => {
            console.log("item---1", res.data.items[0]);
            const addBookObj = {
                "title": res.data.items[0].volumeInfo.title != undefined ? res.data.items[0].volumeInfo.title : res.data.items[0].volumeInfo.subtitle,
                "description": res.data.items[0].volumeInfo.description.toLowerCase(),
                "authors": res.data.items[0].volumeInfo.authors,
                "tags": [localStorage.getItem("user_search")],
                "thumbnail_url": res.data.items[0].volumeInfo.imageLinks.thumbnail,
                "content_url": book_data.buyLink,
                "average_rating": res.data.items[0].volumeInfo.averageRating != undefined ? res.data.items[0].volumeInfo.averageRating : 0
            }
            console.log('addBookObj: ', addBookObj);
            setBookResponse(prevBooks => [...prevBooks, addBookObj]);
        }).catch((error) => {
            dispatch(setLoading(false))
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
            console.log("error", error);
        });
    }

    useEffect(() => {
        const fetchBooks = () => {
            dispatch(setLoading(true))
            var bar = new Promise((resolve, reject) => {
                chatGptResponse.forEach((item, index) => {
                    setTimeout(() => {
                        googleBookApi(item);
                    }, 2000 * index);
                    if(index == chatGptResponse.length - 1) resolve();
                });
            });
            bar.then(() => {
                dispatch(setLoading(false));
            });
        };
        

        if (chatGptResponse.length > 0) {
            fetchBooks();
        }

    }, [chatGptResponse]);

    const AddAllBook = () => {
        AddBook({ "books": bookResponse }).then((response) => {
            if (response.status == 200) {
                dispatch(setLoading(false))
                setChatGptResponse([])
                setBookResponse([])
                setContent(response.data)
            }

        }).catch((error) => {
            dispatch(setLoading(false))
            Swal.fire({
                position: "top-right",
                icon: "error",
                title: error.response ? error.response.data.errors : "Something went wrong",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        });
    }

    if (chatGptResponse.length > 0 && bookResponse.length > 0) {
        if (chatGptResponse.length == bookResponse.length) {
            AddAllBook()
        }
    }




    return (
        <>
            {scroll ? <ScrollToTop /> : null}
            {isLoading ?
                (<Loader loader_status={isLoading} />) :
                (
                    <main className="helprr-main" >
                        {localStorage.getItem("token") ?
                            <OuterHeader /> :
                            <Header />
                        }
                        <section>
                            <div className="container">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="font_50 font_600">Contents</p>
                                    <Link to="/mindful-content" className="decide-btn" onClick={() => localStorage.setItem("help", "decide")}>
                                        Help me decide
                                    </Link>
                                </div>
                                <div className="section-search" >
                                    <div className="row mb-lg-5 mb-2" >
                                        <div className="col-lg-6 col-xl-6 col-12" onClick={() => setState(!state)}>

                                            <div className='selection'>

                                                <div className="position-relative">
                                                    <div className="input-group border-0 custom-dropdown mb-3">
                                                        <input
                                                            type="text"
                                                            className="form-control border-0"
                                                            id="toggle"
                                                            placeholder="What Are You Looking For?"
                                                            aria-label="Recipient's username"
                                                            aria-describedby="basic-addon2"
                                                            value={searchQuery}
                                                            autocomplete="off"
                                                            onChange={handleSearchChange}

                                                        />
                                                        <span
                                                            className="input-group-text arrow-icon border-0 p-2"
                                                            id="basic-addon2"
                                                        >
                                                            <img
                                                                src="assets/images/icon/dropdown-icon.svg"
                                                                alt="image"
                                                                id="arrow-image"
                                                                className={state == false ? "" : "rotated-image"}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div id="content" className={state == false ? "is-hidden drop-sec" : "drop-sec"}>
                                                        <div className="row">
                                                            <div className="col-lg-12 col-xl-12 col-12">

                                                                <ul className="list-unstyled">
                                                                    {filteredItems && filteredItems.map((item, index) => (
                                                                        <li key={index} onClick={(e) => handleInputValue(e)} >{item.tag}</li>
                                                                    ))}

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="row" onClick={() => setState(false)}>
                                        <p className="font_24 mb-lg-4 mb-2">Advanced Options</p>
                                        <div className="col-lg-4 col-xl-4 col-12">
                                            <div className="mb-3">

                                                <div className='selection advanceselection' >
                                                    <Select2 data-minimum-results-for-search="Infinity" className="country-select bg-light form-control js-example-basic-single"
                                                        data={['Book', 'Article', 'Video', 'Audio']}
                                                        options={
                                                            {
                                                                placeholder: 'Type of Content',
                                                            }
                                                        }
                                                        value={selectedValue}
                                                        // onChange={(e) => localStorage.setItem("user_category", e.target.value)}
                                                        onChange={(e) => handlesavecontent(e)}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-xl-4 col-12">
                                            <div className="mb-3">
                                                <div className='selection'>
                                                    <Select2 data-minimum-results-for-search="Infinity" className="country-select bg-light form-control js-example-basic-single"
                                                        data={['Short (<5 minutes)', 'Medium (5-10 minutes)', 'Long (10-30 minutes)', 'Super Long (30+ minutes)']}
                                                        options={
                                                            {
                                                                placeholder: 'Length of Content',
                                                            }
                                                        }
                                                        value={selectedlength}
                                                        onChange={(e) => handlesavecontentlength(e)}
                                                    // onChange={(e) => localStorage.setItem("content_length", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-xl-2 col-md-6 col-12 mb-3">
                                            <button className="search-btn font_18 " onClick={() => SearchData()}>
                                                Search
                                            </button>
                                        </div>
                                        <div className="col-lg-2 col-xl-2 col-md-6 col-12">
                                            <button className="search-btn font_18 " onClick={() => ResetData()}>
                                                {/* <i class="fa-solid fa-arrow-rotate-left"></i> */}
                                                Reset
                                            </button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="other-res-pad" onClick={() => {
                            setState(false)
                        }}>
                            {localStorage.getItem("user_category") == "Book" ?
                                <Book bookdata={content} /> :
                                localStorage.getItem("user_category") == "Video" ?
                                    <Video videodata={content} /> :
                                    localStorage.getItem("user_category") == "Audio" ?
                                        <Audio audiodata={content} /> :
                                        localStorage.getItem("user_category") == "Article" ?
                                            <Artical articledata={content} /> :
                                            (localStorage.getItem("user_search") == "" || !localStorage.getItem("user_search")) &&
                                            <DefaultContent />
                            }


                        </section>
                        <section className="other-res-pad pt-3">
                            <div className="container">
                                <div className="d-flex justify-content-between align-items-center mb-lg-5 mb-3 flex-wrap">
                                    <p className="font_50 font_600 mb-0 sec-title">Daily Affirmations</p>
                                    <Link to="/affirmations">
                                        <p className="font_24 mb-0 see-more-icon">
                                            See More{" "}
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/blue-arroww.svg"} className="ps-2" />
                                        </p>
                                    </Link>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        {affirmationdata[0] != undefined ?
                                            <div className="affirmation-card lh-1">
                                                <p className="font_34 font_600 mb-0">{affirmationdata[0] && affirmationdata[0].affirmation}</p>
                                                <p className="font_32 font_600 white-space-nw">- {affirmationdata[0] && affirmationdata[0].author}</p>

                                            </div> : <></>}
                                        {affirmationdata[1] != undefined ?
                                            <div className="affirmation-card--1">
                                                <p className="font_34 font_600 mb-0">{affirmationdata[1] && affirmationdata[1].affirmation}</p>
                                                <p className="font_32 font_600 white-space-nw">- {affirmationdata[1] && affirmationdata[1].author}</p>
                                            </div> : <></>}
                                    </div>
                                    <div className="col-md-6 col-lg-5 col-xl-5 col-xxl-4 col-12">
                                        {affirmationdata[2] != undefined ? <div className="affirmation-card--2">
                                            <p className="font_34 font_600 mb-0 lh-sm">
                                                {affirmationdata[2] && affirmationdata[2].affirmation}
                                            </p>
                                            <p className="font_32 text-end mb-0 font_600 lh-1">-{affirmationdata[2] && affirmationdata[2].author}</p>
                                        </div> : <></>}
                                        {affirmationdata[3] != undefined ?
                                            <div className="affirmation-card--2 bg-mave">
                                                <p className="font_34 font_600 mb-0 lh-sm text-white">
                                                    {affirmationdata[3] && affirmationdata[3].affirmation}
                                                </p>
                                                <p className="font_32 text-end mb-0 font_600 lh-1 text-white">
                                                    -{affirmationdata[3] && affirmationdata[3].author}
                                                </p>
                                            </div> : <></>}
                                    </div>
                                </div>
                            </div>
                        </section>

                        <Footer />
                    </main>)}

        </>
    )
}

export default Resources


