import React, { useState, useEffect } from 'react'
import OuterHeader from './OuterHeader.js'
import Footer from './Footer.js'
import { Link, useNavigate } from 'react-router-dom'
import Slider from "react-slick";
import Select2 from '../pages/Select2.js';
import '../css2/select2.css'
import Swal from 'sweetalert2'
import { AddRemoveSavedContent, ContentList } from '../api/apiHandler.js'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../store/slice/loaderSlice';


const Artical = (props) => {
    const navigate = useNavigate()
    let articledata = props.articledata
    const UserData = useSelector((state) => JSON.parse(state.userdata))
    const [savedata, setSaveddata] = useState(articledata)
    const [tasksPerPage, setTaskPerPage] = useState(10);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [loadMoreFlag, setLoadMoreFlag] = useState(false);
    const [taskToDisplay, setTaskToDisplay] = useState([]);
    const [isLoadmore, setIsLoadmore] = useState(false);
    const [nocontent, setNocontent] = useState();



    const handlelike = (item_id) => {

        const label = document.querySelector(`div[id="${item_id}"] label`);
        if (label) {
            label.classList.toggle('liked');
        }

        const myaudiodata = {
            content_id: item_id
        }

        AddRemoveSavedContent(myaudiodata).then((response) => {

            if (response.status == 201) {
                Swal.fire({
                    position: "top-right",
                    icon: "success",
                    title: "Article saved successfully",
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                });
                // morecontentdata({ page: currentPageNo })
            }
            if (response.status === 204) {
                // Swal.fire({
                //     position: "top-right",
                //     icon: "success",
                //     title: "Article unsaved successfully",
                //     toast: true,
                //     showConfirmButton: false,
                //     timer: 2000
                // });
            }
        }).catch((error) => {
            console.error(error);

        })
    }





    useEffect(() => {
        if (articledata.length === 10) {
            setCurrentPageNo(1);
            setIsLoadmore(false);
        } else {
            if (articledata.length < 9) {
                setCurrentPageNo(1);
            }
            if (articledata.length > 9) {
                setIsLoadmore(false);
            }
        }
        setSaveddata(articledata);
    }, [articledata]);


    const morecontentdata = (currentPageNo) => {

        ContentList({ page: currentPageNo }).then((response) => {

            if (response.status == 200) {
                if (response.data.page === response.data.total_page_count) {
                    setIsLoadmore(false)
                }
                if (currentPageNo > 1) {
                    const newTasks = response.data.results;
                    setSaveddata(prevTasks => [...prevTasks, ...newTasks]);
                } else {
                    setSaveddata(response.data.results)
                }
            } else if (response.status == 204) {
                setSaveddata([])
                setNocontent(204)
            }

        }).catch((error) => {
            console.log(error);
        })

    }


    function loadMoreTasks() {
        checkPagination();
        setCurrentPageNo(currentPageNo + 1);
        morecontentdata(currentPageNo + 1);
    }

    function checkPagination() {
        const startIndex = (currentPageNo - 1) * tasksPerPage;
        const endIndex = startIndex + tasksPerPage;
        if (startIndex >= savedata.length - 1) {
            setLoadMoreFlag(true);
        }
        else {
            setLoadMoreFlag(false);
        }
    }





    const handlePagination = () => {
        if (loadMoreFlag) {
            // No more data available
            Swal.fire({
                position: "top-right",
                icon: "info",
                title: "No more data available",
                toast: true,
                showConfirmButton: false,
                timer: 2000
            });
        } else {
            loadMoreTasks();
        }
    }

    const handleCreateEventClick = () => {
        Swal.fire({
            position: "top-right",
            icon: "error",
            title: "Subscription required",
            toast: true,
            showConfirmButton: false,
            timer: 2000
        });
    }

    return (
        <>
            <div className="container">
                {localStorage.getItem("user_category") != null && taskToDisplay.length > 0 ?
                    <p className="font_46 font_600 mb-0 sec-title">{localStorage.getItem("user_category")}</p>
                    : null}

                {savedata && savedata.map((item, key) => {
                    return (
                        <>
                            {UserData && UserData.data.is_subscription_active == true ?
                                <div className="card-margin">
                                    <div className="row">
                                        <div className="col-lg-4 col-xl-4 col-12">
                                            <div className="position-relative" key={item.id}>
                                                <div className="results-card-img cursor-pointer" onClick={() => navigate(`/content-article/${item.id}`)}>
                                                    <img src={item.thumbnail} alt="" />
                                                </div>



                                                <div className="heart-icon" id={item.id} onClick={(e) => handlelike(e.target.id)} >

                                                    <input
                                                        id={item.id}
                                                        type="checkbox"
                                                        name="rating"

                                                    />

                                                    <label htmlFor={item.id} className={item.is_saved_by_user ? 'liked' : ''}>
                                                        <i className="fa-solid fa-heart" id={item.id} />
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-xl-8 col-12">
                                            <div className="d-flex column-display justify-content-between h-100">
                                                <div>
                                                    <p className="font_32 cursor-pointer" onClick={() => navigate(`/content-article/${item.id}`)}>{item.title}</p>
                                                    <p className="font_22 text-desc">
                                                        {item.description}
                                                    </p>
                                                </div>

                                                <div className="row  align-items-center ">
                                                    <div className="col-lg-6 col-xl-5 col-12">
                                                        <div className="d-flex gap-3 stars-img">

                                                            {item && Math.floor(item.average_rating) == 1 ?
                                                                <>
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                </> : Math.floor(item.average_rating) == 2 ?
                                                                    <>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                    </> : Math.floor(item.average_rating) == 3 ?
                                                                        <>
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                        </> : Math.floor(item.average_rating) == 4 ?
                                                                            <>
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                            </> : Math.floor(item.average_rating) == 5 ?
                                                                                <>
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                </> : <>
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} id='1' alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-7 col-12">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="font_16 mb-0">Read Time: {item.read_time} minutes</p>
                                                            <div className="d-flex align-items-center">
                                                                <p className="font_16 mb-0 me-2">Go To Article</p>
                                                                <button onClick={() => navigate(`/content-article/${item.id}`)}>
                                                                    <img
                                                                        src="assets/images/icon/purple-round-arrow.svg"
                                                                        className="arrow-img-height"
                                                                        alt=""
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="card-margin">
                                    <div className="row">
                                        <div className="col-lg-4 col-xl-4 col-12">
                                            <div className="position-relative">
                                                {item.is_paid == true ?
                                                    <div className="results-card-img">
                                                        <img src={item.thumbnail} alt="" />
                                                    </div> :
                                                    <div className="results-card-img cursor-pointer" onClick={() => navigate(`/content-article/${item.id}`)}>
                                                        <img src={item.thumbnail} alt="" />
                                                    </div>}

                                                {item.is_paid == true ?
                                                    <div className="lock-icon">
                                                        <i className="fa-solid fa-lock text-mave font_24" />
                                                    </div> : ""}
                                                {/* <div className="heart-icon" onClick={() => handlelike(item.id)}>
                                            <i className="fa-regular fa-heart text-mave font_24" />
                                        </div> */}
                                                {item.is_paid == true ? "" :
                                                    <div className="heart-icon" id={item.id} onClick={(e) => handlelike(e.target.id)}>
                                                        <input
                                                            id={item.id}
                                                            type="checkbox"
                                                            name="rating"
                                                        />
                                                        <label htmlFor={item.id} className={item.is_saved_by_user == true ? 'liked' : ''}>
                                                            <i className="fa-solid fa-heart" id={item.id} />
                                                        </label>
                                                    </div>}

                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-xl-8 col-12">
                                            <div className="d-flex column-display justify-content-between h-100">
                                                <div>
                                                    {item.is_paid == true ?
                                                        <p className="font_32">{item.title}</p> :
                                                        <p className="font_32 cursor-pointer" onClick={() => navigate(`/content-article/${item.id}`)}>{item.title}</p>}
                                                    <p className="font_22 text-desc">
                                                        {item.description}
                                                    </p>

                                                </div>
                                                <div className="row  align-items-center ">
                                                    <div className="col-lg-6 col-xl-5 col-12">
                                                        <div className="d-flex gap-3 stars-img">

                                                            {item && item.rating == 1 ?
                                                                <>
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                </> : item.rating == 2 ?
                                                                    <>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                    </> : item.rating == 3 ?
                                                                        <>
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                        </> : item.rating == 4 ?
                                                                            <>
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                            </> : item.rating == 5 ?
                                                                                <>
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} id='1' alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-colored.svg"} alt="" />
                                                                                </> : <>
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} id='1' alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/star-deselected.svg"} alt="" />
                                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-xl-7 col-12">
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="font_16 mb-0">Read Time: {item.read_time} minutes</p>
                                                            <div className="d-flex align-items-center">
                                                                <p className="font_16 mb-0 me-2">Go To Article</p>
                                                                {item.is_paid == true ?
                                                                    <button onClick={handleCreateEventClick}>
                                                                        <img
                                                                            src="assets/images/icon/purple-round-arrow.svg"
                                                                            className="arrow-img-height"
                                                                            alt=""
                                                                        />
                                                                    </button> :
                                                                    <button onClick={() => navigate(`/content-article/${item.id}`)}>
                                                                        <img
                                                                            src="assets/images/icon/purple-round-arrow.svg"
                                                                            className="arrow-img-height"
                                                                            alt=""
                                                                        />
                                                                    </button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    )
                })}
                {!isLoadmore && articledata.length >= 10 ?
                    <div className="d-flex justify-content-center mt-4 mb-3">
                        <button className="primary-btn bg-blue" onClick={handlePagination}>
                            Load more
                        </button>

                    </div>
                    : null}
            </div>
        </>
    )
}

export default Artical